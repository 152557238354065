import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CareersWrapper } from "../styles";

export default function Careers() {
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedJob, setExpandedJob] = useState(null);

  const toggleExpand = (jobId: any) => {
    setExpandedJob(expandedJob === jobId ? null : jobId);
  };

  const careersData = [
    {
      id: 1,
      designation: "Full Stack Developer",
      job_id: "S26585",
      job_postedon: "04-02-2024",
      description:
        "We are looking to add a skilled Full Stack Developer to our vibrant team. You will be in charge of creating and managing front-end and back-end apps as a Full Stack Developer, making sure that they integrate seamlessly. You'll collaborate closely with our product and design teams to provide excellent, user-friendly software solutions.",
      responsibilities: [
        "Developing and maintaining web applications utilising contemporary frameworks and technologies is your responsibility.",
        "Create, execute, and verify functionalities over the whole web development stack.",
        "Collaborate with cross-functional teams to define, develop, and implement new features.",
        "Applications should be optimised for optimum performance and scalability.",
        "Debugg and troubleshoot problems to guarantee that apps run smoothly.",
      ],
      requiredSkills: [
        "Proficiency with front-end technologies, including HTML5, CSS3, JavaScript, and contemporary JavaScript frameworks (such React, Angular, and Vue.js), is necessary.",
        "solid experience developing back-end applications in Python, Java, and Node.js.",
        "familiarity with both relational and non-relational databases, such as MongoDB and MySQL.",
        "familiarity with server-side CSS preprocessors (such as Sass and Less).",
        "knowledge of version control systems like Git.",
        "comprehension of recommended practices for online security.",
        "Good communication and problem-solving abilities.",
        "Capacity to operate both individually and together in a hectic setting.",
      ],
    },
    {
      id: 2,
      designation: ".NET Developer",
      job_id: "S23558",
      job_postedon: "25-02-2024",
      description:
        "We are looking to fill our team with a talented.NET Developer. Using the.NET framework, you will be in charge of creating and managing high-caliber software solutions as a.NET developer. Together with our development team, you will collaborate closely to produce scalable, reliable apps that satisfy our clients' demands.",
      responsibilities: [
        "Create, create, and manage online and desktop applications utilising the.NET framework.",
        "Collaborate with cross-functional teams to develop, create, and deploy new features.",
        "Write clean, effective, and well- proved law that follows stylish practices.",
        "Test and debug apps to ensure they run smoothly and reliably.",
        "Participate in code reviews to verify that the code is high quality and consistent.",
        "Stay current on the newest technology and market trends in.NET development.",
      ],
      requiredSkills: [
        "Required skills include proficiency in C# and the.NET framework.",
        "Experience with ASP.NET MVC and/or ASP.NET Core.",
        "A solid comprehension of object-oriented programming (OOP) principles.",
        "Front-end technology knowledge, including HTML5, CSS3, JavaScript, and jQuery.",
        "Proficiency in SQL Server or similar relational database systems.",
        "Understanding the software development lifecycle (SDLC) techniques.",
        "Excellent problem-solving and analytical abilities.",
        "Ability to operate both alone and collaboratively in a team setting.",
      ],
    },
    {
      id: 3,
      designation: "Java Developer",
      job_id: "S26845",
      job_postedon: "25-02-2024",
      description:
        "We are seeking a skilled Java Developer to join our team. As a Java Developer, you will be responsible for developing and maintaining high-quality software solutions using Java and related technologies. You will collaborate with our development team to design and implement scalable, reliable, and efficient applications.",
      responsibilities: [
        "Design, develop, and maintain Java-based web applications.",
        "Implement new features and enhancements to existing applications.",
        "Work closely with cross-functional teams to understand requirements and deliver solutions.",
        "Write clean, efficient, and well-documented code following best practices.",
        "Test and debug applications to ensure optimal performance and reliability.",
        "Stay up-to-date with the latest technologies and industry trends in Java development.",
      ],
      requiredSkills: [
        "Proficiency in Java programming language.",
        "Experience with Spring Framework (Spring Boot, Spring MVC, Spring Security, etc.).",
        "Strong understanding of object-oriented programming (OOP) principles.",
        "Knowledge of web technologies such as HTML, CSS, and JavaScript.",
        "Experience with relational databases (e.g., MySQL, PostgreSQL).",
        "Familiarity with version control systems such as Git.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 4,
      designation: "SAP Developer",
      job_id: "S25482",
      job_postedon: "06-02-2024",
      description:
        "We are looking for a skilled SAP Developer to join our team. As a SAP Developer, you will be responsible for developing and maintaining SAP solutions to meet business requirements. You will collaborate with our clients and internal teams to design, implement, and support SAP applications and integrations.",
      responsibilities: [
        "Design, develop, and configure SAP solutions based on business requirements.",
        "Customize and enhance existing SAP applications and functionalities.",
        "Collaborate with cross-functional teams to define, design, and implement SAP projects.",
        "Provide technical support and troubleshooting for SAP applications and integrations.",
        "Ensure the integrity and security of SAP systems and data.",
        "Stay up-to-date with the latest SAP technologies and best practices.",
      ],
      requiredSkills: [
        "Strong knowledge of SAP modules such as SAP ERP, SAP S/4HANA, SAP BW/4HANA, etc.",
        "Experience with SAP ABAP programming language.",
        "Understanding of SAP Fiori/UI5 development.",
        "Knowledge of SAP integration technologies (RFC, BAPI, IDoc, PI/PO, etc.).",
        "Ability to analyze and translate business requirements into technical solutions.",
        "Excellent problem-solving and communication skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 5,
      designation: "Sales Force Developer",
      job_id: "S23647",
      job_postedon: "12-02-2024",
      description:
        "We are seeking a talented Sales Force Developer to join our team. As a Sales Force Developer, you will be responsible for designing, developing, and maintaining custom solutions on the Salesforce platform. You will work closely with our clients and internal teams to deliver high-quality Salesforce applications that meet business requirements.",
      responsibilities: [
        "Design, develop, and customize Salesforce solutions to meet business requirements.",
        "Implement and integrate Salesforce applications, modules, and features.",
        "Collaborate with stakeholders to define, design, and deliver Salesforce projects.",
        "Provide technical support and troubleshooting for Salesforce applications and integrations.",
        "Ensure data integrity and security within the Salesforce environment.",
        "Stay up-to-date with Salesforce technologies and best practices.",
      ],
      requiredSkills: [
        "Proficiency in Salesforce development technologies such as Apex, Visualforce, Lightning Components.",
        "Experience with Salesforce configuration, customization, and integration.",
        "Knowledge of Salesforce CRM functionalities and modules.",
        "Understanding of Salesforce development best practices and design patterns.",
        "Ability to work independently and collaboratively in a team environment.",
        "Excellent problem-solving and communication skills.",
      ],
    },
    {
      id: 6,
      designation: "Data Engineer",
      job_id: "S22658",
      job_postedon: "10-02-2024",
      description:
        "We are looking for a skilled Data Engineer to join our team. As a Data Engineer, you will be responsible for designing, building, and maintaining scalable data pipelines and systems. You will work closely with our data science and analytics teams to ensure efficient data processing and analysis.",
      responsibilities: [
        "Design, build, and maintain data pipelines and ETL processes.",
        "Implement data integration and transformation solutions for structured and unstructured data.",
        "Optimize data pipelines for performance, scalability, and reliability.",
        "Collaborate with data scientists and analysts to support their data needs.",
        "Ensure data quality, consistency, and integrity across various data sources and systems.",
        "Stay up-to-date with emerging technologies and best practices in data engineering.",
      ],
      requiredSkills: [
        "Proficiency in programming languages such as Python, Java, or Scala.",
        "Experience with data processing frameworks such as Apache Spark, Apache Beam, or Apache Flink.",
        "Knowledge of distributed computing and parallel processing.",
        "Familiarity with cloud platforms and services (e.g., AWS, GCP, Azure).",
        "Understanding of relational and NoSQL databases.",
        "Excellent problem-solving and analytical skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 7,
      designation: "RPA Developer",
      job_id: "S26548",
      job_postedon: "15-03-2024",
      description:
        "We are looking for a talented RPA Developer to join our team. As an RPA Developer, you will be responsible for designing, developing, and implementing robotic process automation solutions to streamline business processes. You will work closely with stakeholders to understand requirements and deliver efficient automation solutions.",
      responsibilities: [
        "Design, develop, and implement robotic process automation solutions using RPA tools such as UiPath, Automation Anywhere, or Blue Prism.",
        "Collaborate with business analysts and stakeholders to gather requirements and define automation workflows.",
        "Develop and maintain bots to automate repetitive tasks and business processes.",
        "Test and debug automation solutions to ensure they meet quality standards and business requirements.",
        "Provide technical support and troubleshooting for deployed bots.",
        "Stay up-to-date with the latest RPA technologies and best practices.",
      ],
      requiredSkills: [
        "Proficiency in RPA development tools such as UiPath, Automation Anywhere, or Blue Prism.",
        "Experience with programming languages such as C#, Java, or Python.",
        "Strong problem-solving and analytical skills.",
        "Ability to understand and document business processes.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 8,
      designation: "Data Analyst",
      job_id: "S27698",
      job_postedon: "18-03-2024",
      description:
        "We are seeking a Data Analyst to join our team. As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting data to inform business decisions. You will work closely with stakeholders to identify data needs and provide insights that drive strategic initiatives.",
      responsibilities: [
        "Collect, clean, and preprocess data from various sources for analysis.",
        "Perform exploratory data analysis to uncover patterns, trends, and insights.",
        "Develop and maintain dashboards and reports to visualize and communicate key metrics and findings.",
        "Collaborate with stakeholders to understand business requirements and provide data-driven recommendations.",
        "Conduct statistical analysis and modeling to support decision-making processes.",
        "Stay up-to-date with data analysis techniques and tools.",
      ],
      requiredSkills: [
        "Proficiency in data analysis tools and programming languages such as Python, R, SQL, or Excel.",
        "Experience with data visualization tools such as Tableau, Power BI, or Google Data Studio.",
        "Strong analytical and problem-solving skills.",
        "Ability to communicate complex findings and insights to non-technical stakeholders.",
        "Knowledge of statistical analysis and modeling techniques.",
        "Attention to detail and ability to work with large datasets.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 9,
      designation: "BI Developer",
      job_id: "S28746",
      job_postedon: "20-03-2024",
      description:
        "We are looking for a BI Developer to join our team. As a BI Developer, you will be responsible for designing, developing, and maintaining business intelligence solutions. You will work closely with stakeholders to gather requirements and deliver data-driven insights that support strategic decision-making.",
      responsibilities: [
        "Design, develop, and maintain BI solutions, including data models, dashboards, and reports.",
        "Collaborate with business stakeholders to gather requirements and define KPIs.",
        "Perform data profiling and analysis to understand data quality and identify opportunities for improvement.",
        "Optimize SQL queries and data pipelines for performance and efficiency.",
        "Develop and maintain ETL processes to extract, transform, and load data from various sources.",
        "Stay up-to-date with BI tools and technologies.",
      ],
      requiredSkills: [
        "Proficiency in SQL and experience with relational databases such as SQL Server, PostgreSQL, or Oracle.",
        "Experience with BI tools such as Microsoft Power BI, Tableau, or QlikView.",
        "Strong analytical and problem-solving skills.",
        "Ability to work with stakeholders to understand business requirements.",
        "Knowledge of data warehousing concepts and techniques.",
        "Experience with ETL tools and processes.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 10,
      designation: "QA Engineer",
      job_id: "S29854",
      job_postedon: "22-03-2024",
      description:
        "We are seeking a QA Engineer to join our team. As a QA Engineer, you will be responsible for ensuring the quality of our software products through manual and automated testing. You will work closely with development teams to identify and resolve issues and ensure that our products meet quality standards.",
      responsibilities: [
        "Develop and execute test plans, test cases, and test scripts to validate software functionality.",
        "Perform manual testing to identify bugs, defects, and usability issues.",
        "Develop and maintain automated test scripts and frameworks to improve testing efficiency.",
        "Collaborate with development teams to reproduce, debug, and resolve issues.",
        "Conduct regression testing to ensure that software changes do not adversely affect existing functionality.",
        "Stay up-to-date with QA tools, techniques, and best practices.",
      ],
      requiredSkills: [
        "Experience with software testing methodologies, tools, and techniques.",
        "Proficiency in test automation tools such as Selenium, Cypress, or TestComplete.",
        "Strong analytical and problem-solving skills.",
        "Ability to work with cross-functional teams to identify and resolve issues.",
        "Knowledge of software development lifecycle (SDLC) processes.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 11,
      designation: "Software Engineer",
      job_id: "S30962",
      job_postedon: "24-03-2024",
      description:
        "We are looking for a Software Engineer to join our team. As a Software Engineer, you will be responsible for designing, developing, and maintaining software solutions. You will work closely with stakeholders to gather requirements and deliver high-quality software products that meet customer needs.",
      responsibilities: [
        "Design, develop, and maintain software applications and systems.",
        "Collaborate with stakeholders to gather requirements and define project scope.",
        "Write clean, efficient, and maintainable code following best practices.",
        "Test and debug software applications to ensure they meet quality standards.",
        "Conduct code reviews to provide feedback and ensure code quality.",
        "Stay up-to-date with software development tools, technologies, and best practices.",
      ],
      requiredSkills: [
        "Proficiency in programming languages such as Java, Python, C#, or JavaScript.",
        "Experience with software development frameworks and libraries.",
        "Strong problem-solving and analytical skills.",
        "Ability to work with stakeholders to understand requirements and deliver solutions.",
        "Knowledge of software engineering principles and methodologies.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
    {
      id: 12,
      designation: "Cybersecurity Analyst",
      job_id: "S31874",
      job_postedon: "26-03-2024",
      description:
        "We are seeking a Cybersecurity Analyst to join our team. As a Cybersecurity Analyst, you will be responsible for identifying, assessing, and mitigating security risks to protect our organization's information assets. You will work closely with IT and business stakeholders to implement security measures and ensure compliance with security policies and regulations.",
      responsibilities: [
        "Identify and assess security risks and vulnerabilities through security assessments and penetration testing.",
        "Develop and implement security controls and measures to protect against security threats and attacks.",
        "Monitor security events and incidents to detect and respond to security breaches and incidents.",
        "Conduct security audits and assessments to ensure compliance with security policies and regulations.",
        "Provide security awareness training and guidance to employees.",
        "Stay up-to-date with the latest cybersecurity threats, trends, and best practices.",
      ],
      requiredSkills: [
        "Knowledge of cybersecurity principles, technologies, and best practices.",
        "Experience with security assessment tools and techniques.",
        "Strong analytical and problem-solving skills.",
        "Ability to communicate complex technical concepts to non-technical stakeholders.",
        "Understanding of regulatory compliance requirements (e.g., GDPR, HIPAA, PCI DSS).",
        "Certifications such as CISSP, CISM, or CompTIA Security+ are a plus.",
        "Ability to work independently and collaboratively in a team environment.",
      ],
    },
  ];

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const filteredCareersData = careersData.filter((item) =>
    item.designation.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="rs-process style2 pt-30 pb-50 md-pt-30 md-pb-50">
        <div className="container custom">
          <div className="row y-middle mb-50">
            <div className="col-lg-12">
              <div className="sec-title md-mb-30">
                <h2 className="title">Job Openings at VSIT SYSTEMS INC</h2>
                <input
                  type="text"
                  placeholder="Search jobs..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="mt-4"
                />
              </div>
            </div>
          </div>
          <CareersWrapper>
            <div className="container custom">
              <div className="row">
                {filteredCareersData.length === 0 ? (
                  <div className="col-lg-12 mt-5 mb-5">
                    <h4>No Results Found</h4>
                  </div>
                ) : (
                  filteredCareersData.map((item) => {
                    return (
                      <div className="col-lg-6 mb-30" key={item.id}>
                        <div className="rs-addon-number">
                          <div className="number-part">
                            <div className="number-title d-flex align-items-center justify-content-between">
                              <span className="title">{item.designation}</span>
                              <i
                                className={`fa fa-chevron-${
                                  expandedJob === item.id ? "up" : "down"
                                } fa-1x`}
                                onClick={() => toggleExpand(item.id)}
                              ></i>
                            </div>
                            <div className="number-txt">
                              <div className="loac-text mb-1 pb-0">
                                <strong>JOB ID:</strong> {item.job_id}
                              </div>
                              <div className="loac-text">
                                <strong>Job Posted On:</strong>{" "}
                                {item.job_postedon}
                              </div>
                              <p>{item.description}</p>
                              {expandedJob === item.id && (
                                <div>
                                  <section>
                                    <h4 className="mt-5">Responsibilities:</h4>
                                    <ul>
                                      {item.responsibilities.map(
                                        (responsibility, index) => (
                                          <li key={index}>{responsibility}</li>
                                        )
                                      )}
                                    </ul>
                                  </section>
                                  <section>
                                    <h4>Required Skills:</h4>
                                    <ul>
                                      {item.requiredSkills.map(
                                        (skill, index) => (
                                          <li key={index}>{skill}</li>
                                        )
                                      )}
                                    </ul>
                                  </section>
                                </div>
                              )}
                            </div>
                            <div className="btn-part">
                              <Link
                                to="/RegistrationForm"
                                className="readon apply"
                              >
                                Apply Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </CareersWrapper>
        </div>
      </div>
    </div>
  );
}
