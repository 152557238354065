import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";
import "font-awesome/css/font-awesome.min.css";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { Router } from "react-router-dom";
import history from "./history";
import "react-tabs/style/react-tabs.css";
import "react-animated-slider/build/horizontal.css";
import "./slider-animations.css";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
