import React from "react";
import Img1 from "../../../assets/images/1.svg";
import { Link } from "react-router-dom";

export default function CloudConsulting() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-20">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">Cloud Guidance</h2>
                <p className="mt-10">
                  After doing a thorough assessment of your current IT
                  infrastructure and business procedures, we evaluate the
                  necessity of adopting cloud computing and provide a thorough
                  feasibility analysis and business case.
                </p>
                <p className="mt-10">
                  Our skilled cloud architects suggest the best cloud provider
                  and deployment option, offering tactical guidance on a cloud
                  strategy that works and the necessary IaaS, PaaS, or SaaS
                  services. In addition, we painstakingly create a robust
                  security strategy and a plan for risk mitigation.
                </p>

                <div className="images-part pt-30 pb-50">
                  <img src={Img1} alt="Images" className="w-100 " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services home-style2 pt-50 pb-50 md-pt-50 md-pb-50 gray-bg">
        <div className="container">
          <div className="sec-title2 text-center md-left mb-40">
            <h2 className="title pb-20">
              What Consulting for Cloud Computing Offers
            </h2>
            <p>
              We bring a wealth of expertise to the table, boasting a team
              proficient in cutting-edge <br />
              technologies and a track record of successful projects.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 md-mb-50 pr-30 md-pr-l5">
              <div className="services-item mb-45">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="data-engineering">Acceptance of cloud </Link>
                  </h3>
                  <p className="services-txt">
                    Arrange, prioritise, and schedule the cloud-native
                    application development, cloud data warehouse building, and
                    IT infrastructure migration.
                  </p>
                </div>
              </div>

              <div className="services-item mb-45">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="data-science">Feasibility</Link>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    An assessment of your cloud projects, including an estimate
                    of Total Cost of Ownership (TCO) and ROI.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-15">
              <div className="services-item mb-45">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="snowflake-administration">
                      Meetings with the C-Suite
                    </Link>
                  </h3>
                  <p className="services-txt">
                    Help in understanding the benefits and challenges of cloud
                    migration or cloud-native development, and identify the
                    expertise and resources required to make the project
                    successful.
                  </p>
                </div>
              </div>
              <div className="services-item ">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="data-strategy">Migration</Link>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    cloud migration of legacy infrastructure using
                    replatforming, rehosting, and refactoring techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row y-middle mt-20">
        <div className="d-flex justify-content-around mt-30 box-content">
          <div className="sec-title3 d-flex justify-content-center flex-column">
            <h2 className="title pb-10 text-center">
              {" "}
              So, why do you hesitate?{" "}
            </h2>
            <h2 className="title pb-20">
              Start using VSIT Systems INC right now to experience the impact it
              can have on your company!
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
