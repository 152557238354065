import React from "react";

export default function DataStrategy() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  Importance of Data Strategy in Business Evolution
                </h2>
                <p className="margin-0 pb-40">
                  Virtually every business accumulates data in diverse formats,
                  and a data strategy aids organizations in sorting, processing,
                  and analyzing this data. Moreover, it positions a company
                  favorably to tackle challenges such as:
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Limited understanding of critical business components like
                      supply chains, customers, and competitive landscapes.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data analysis capabilities hindered by issues such as data
                      privacy, integrity, and quality concerns.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Uncertainty regarding objectives and current company
                      needs, potentially resolved with descriptive analytics.
                    </span>
                  </li>
                </ul>
                <div className="btn-part mt-42">
                  <a
                    className="readon consultant paste-btn more"
                    href="https://www.udemy.com/course/the-data-strategy-course-building-a-data-driven-business/"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img
                  src="assets/images/pages/data-strategy/1.png"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">
              The data strategy consists of four key elements.
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/4.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Company plan</a>
                  </h2>
                  <p className="services-txt">
                    {" "}
                    Your overarching business strategy, encompassing the
                    approaches you utilize to operate and improve your company,
                    should be bolstered and propelled by your data strategy.
                  </p>
                  <p className="services-txt">
                    {" "}
                    To achieve this, ensure your data strategy incorporates
                    measurable goals and objectives that align with your
                    overarching business plan. For instance, one objective of
                    your data strategy could be to keep data storage costs below
                    a certain threshold. To accomplish this, the plan might
                    outline storage solutions within budgetary constraints along
                    with recommended practices to help users minimize storage
                    expenses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50 mt-30">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/2.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Organizational roles</a>
                  </h2>
                  <p className="services-txt">
                    To foster collaboration and prevent redundancy, a data
                    strategy should emphasize organizational roles by
                    documenting responsibilities regarding data usage.
                  </p>
                  <ul className="icon-item mt-20">
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Data engineers manage the data pipeline and are tasked
                        with constructing an efficient and dependable data
                        architecture.
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        The individuals responsible for data engineering oversee
                        the data pipeline and are accountable for establishing a
                        reliable and efficient data architecture.
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Individuals skilled in data analysis and interpretation
                        are commonly referred to as data analysts.
                      </span>
                    </li>
                    <li>
                      <span className="list-icon">
                        <i className="fa fa-check" />
                      </span>
                      <span className="list-text">
                        Business managers play a crucial role in supervising
                        data operations and reviewing data reports.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50 ">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/3.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Information architecture</a>
                  </h2>
                  <p className="services-txt">
                    The initial step in crafting your data architecture involves
                    identifying datasets shared across business divisions within
                    the organization. Utilize data catalogs as valuable
                    resources for this purpose. If a data catalog is
                    unavailable, conduct a thorough review of data sources with
                    your team and individuals who engage with the data.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/1.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Data management</a>
                  </h2>
                  <p className="services-txt">
                    The data management team encourages all team members to
                    perceive data as a valuable asset to the company rather than
                    just a byproduct of business operations. This approach
                    motivates all employees in your company to handle data in
                    alignment with established policies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Companies formulate a data strategy to:</h2>
            <ul className="icon-item mt-30">
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Handle vast datasets critical to the organization's success.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Consider effective management of trends and future prospects.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Foster creativity and cultivate a data-centric culture.
                </span>
              </li>
              <li>
                <span className="list-icon">
                  <i className="fa fa-check" />
                </span>
                <span className="list-text">
                  Encourage a reevaluation of decision-making processes within
                  the organization.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
