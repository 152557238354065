import React from "react";
import { Link } from "react-router-dom";
import Router from "../../routes";
import Img10 from "../../../assets/images/10.png";

const Header = () => {
  return (
    <>
      {/*Full width header Start*/}
      <div className="full-width-header">
        {/*Header Start*/}
        <header id="rs-header" className="rs-header style2 header-transparent">
          {/* Topbar Area Start */}
          <div className="topbar-area style1">
            <div className="container custom">
              <div className="row y-middle">
                <div className="col-lg-7">
                  <div className="topbar-contact">
                    <ul>
                      <li>
                        <i className="flaticon-email" />
                        <b>Email Us: </b>

                        <a href="mailto:info@vsitsystems.com">
                          info@vsitsystems.com{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 text-right">
                  <div className="toolbar-sl-share">
                    <ul>
                      <li className="text-white">
                        <i className="flaticon-location mr-10 " />
                        6200 chase oak Dr Suite 101 Plano Texas 75023
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Topbar Area End */}

          {/* Menu Start */}
          <div className="menu-area menu-sticky">
            <div className="container custom">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="">
                    <a href="/">
                      <img src="assets/images/brand-logo.svg" alt="logo" />
                    </a>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <li className="menu-item-has-children">
                            <a href="/">Services</a>
                            <ul className="sub-menu">
                              <li>
                                <Link to="">
                                  <h4>
                                    <b>AI and Data</b>
                                  </h4>
                                </Link>
                              </li>

                              <li>
                                <Link to="data-strategy">Data Strategy</Link>
                              </li>
                              <li>
                                <Link to="data-science">Data Science</Link>
                              </li>
                              <li>
                                <Link to="data-engineering">
                                  Data Engineering
                                </Link>
                              </li>

                              {/* <li>
                                <Link to="/Overview">Overview</Link>
                              </li>

                              <li>
                                <Link to="/Payroll">Payroll</Link>
                              </li> */}
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="/">Technology</a>
                            <ul className="sub-menu">
                              <div className="row d-flex">
                                <div className="">
                                  <div className="d-flex flex-column">
                                    <div className="">
                                      <li>
                                        <Link to="/bigdata">Big Data</Link>
                                      </li>
                                      <li>
                                        <Link to="/aws">AWS</Link>
                                      </li>

                                      <li>
                                        <Link to="/cloud-foundation">
                                          Cloud Foundation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="managed-mlops">
                                          Cloud DataOps
                                        </Link>
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </li>

                          <li className="menu-item-has-children">
                            <a href="/">Industry Expertise</a>
                            <ul className="sub-menu">
                              <div className="row d-flex align-items-center justify-content-center">
                                {/* <li>
                                  <Link to="/ITServices">IT Services</Link>
                                </li> */}
                                <li>
                                  <Link to="/ITStrategyConsulting">
                                    IT Strategy Consulting
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/CloudConsulting">
                                    Cloud Consulting
                                  </Link>
                                </li>
                              </div>
                            </ul>
                          </li>

                          <li className="menu-item-has-children">
                            <Link to="careers">Company</Link>

                            <ul className="sub-menu">
                              <div className="row d-flex align-items-center justify-content-center">
                                <div className="col-md-4">
                                  <li>
                                    <Link to="careers">Careers</Link>
                                  </li>

                                  <li>
                                    <Link to="videos">Learning</Link>
                                  </li>
                                </div>
                                <div className="col-md-8 d-flex justify-content-center">
                                  <img
                                    src={Img10}
                                    style={{ width: "50%" }}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </ul>
                          </li>
                          <li className>
                            <Link to="/contact-us">Reach Us</Link>
                          </li>
                        </ul>{" "}
                        {/* //.nav-menu */}
                      </nav>
                    </div>{" "}
                    {/* //.main-menu */}
                  </div>
                </div>

                <div className="col-cell">
                  <div class="btn-part">
                    <Link
                      to="/contact-us"
                      class="readon consultant paste-btn more"
                    >
                      Let's discuss
                    </Link>
                  </div>
                </div>

                <div className="col-cell hidden-lg hidden-md hidden-sm visible-xs">
                  <div className="expand-btn-inner">
                    <ul>
                      <li className="humburger">
                        <a
                          id="nav-expander"
                          className="nav-expander bar"
                          href="/"
                        >
                          <div className="bar">
                            <span className="dot1" />
                            <span className="dot2" />
                            <span className="dot3" />
                            <span className="dot4" />
                            <span className="dot5" />
                            <span className="dot6" />
                            <span className="dot7" />
                            <span className="dot8" />
                            <span className="dot9" />
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}

          {/* Canvas Menu start */}
          <nav className="right_menu_togle hidden-md">
            <div className="close-btn">
              <a id="nav-close" className="nav-close" href="/">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <div className="canvas-logo">
              <a href="/">
                <img src="assets/images/brand-logo.svg" alt="logo" />
              </a>
            </div>
            <div className="offcanvas-text">
              <p>
                VSIT Systems INC is a leading IT solutions company that excels
                in delivering AI and ML services. With an experience of #years,
                we aim to deliver prime solutions to our clients.{" "}
              </p>
            </div>
            <div className="media-img">
              <img src="assets/images/pages/ai/3.gif" alt="Images" />
            </div>
            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Address</h4>
                    <em>05 kandi BR. New York</em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Email</h4>
                    <em>
                      <a href="mailto:support@VSIT Systems INC.com">
                        support@VSIT Systems INC.com
                      </a>
                    </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call" />
                  </div>
                  <div className="info-content">
                    <h4 className="title">Phone</h4>
                    <em>+019988772</em>
                  </div>
                </div>
              </div>
              <ul className="social">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100071401861585"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/suchi-erp-inc-erp-5744231bb"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/ErpSuchi"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/erpsuchi.neet?utm_source=qr"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* Canvas Menu end */}

          {/* Canvas Mobile Menu start */}
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a id="nav-close2" className="nav-close" href="/">
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <ul className="nav-menu">
              <li className="menu-item-has-children">
                <a href="/">Services</a>
                <ul className="sub-menu">
                  <li>
                    <Link to="">
                      <h4>
                        <b>AI and Data</b>
                      </h4>
                    </Link>
                  </li>

                  <li>
                    <Link to="data-strategy">Data Strategy</Link>
                  </li>
                  <li>
                    <Link to="data-science">Data Science</Link>
                  </li>
                  <li>
                    <Link to="data-engineering">Data Engineering</Link>
                  </li>

                  {/* <li>
                    <Link to="/Overview">Overview</Link>
                  </li>

                  <li>
                    <Link to="/Payroll">Payroll</Link>
                  </li> */}
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="/">Technology</a>
                <ul className="sub-menu">
                  <div className="row d-flex">
                    <div className="">
                      <div className="d-flex flex-column">
                        <div className="">
                          <li>
                            <Link to="/bigdata">Big Data</Link>
                          </li>
                          <li>
                            <Link to="/aws">AWS</Link>
                          </li>

                          <li>
                            <Link to="/cloud-foundation">Cloud Foundation</Link>
                          </li>
                          <li>
                            <Link to="managed-mlops">Cloud DataOps</Link>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <a href="/">Industry Expertise</a>
                <ul className="sub-menu">
                  <div className="row d-flex align-items-center justify-content-center">
                    {/* <li>
                      <Link to="/ITServices">IT Services</Link>
                    </li> */}
                    <li>
                      <Link to="/ITStrategyConsulting">
                        IT Strategy Consulting
                      </Link>
                    </li>
                    <li>
                      <Link to="/CloudConsulting">Cloud Consulting</Link>
                    </li>
                  </div>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to="careers">Company</Link>

                <ul className="sub-menu">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-4">
                      <li>
                        <Link to="careers">Careers</Link>
                      </li>

                      <li>
                        <Link to="videos">Learning</Link>
                      </li>
                    </div>
                    <div className="col-md-8 d-flex justify-content-center">
                      <img src={Img10} style={{ width: "50%" }} alt="" />
                    </div>
                  </div>
                </ul>
              </li>
              <li className>
                <Link to="/contact-us">Reach Us</Link>
              </li>
            </ul>{" "}
          </nav>
          {/* Canvas Menu end */}
        </header>
        {/*Header End*/}
      </div>
      {/*Full width header End*/}

      <Router />
    </>
  );
};

export default Header;
