import styled, { css } from "styled-components";
import { sideBar, arrowDimentions } from "../variables";

interface Selected {
  isOpen?: boolean;
}

export const flexCenter = css`
  align-items: center;
  justify-content: center;
`;

export const alignedCenter = css`
  align-items: center;
`;

export const displayFlex = css`
  display: flex;
`;

export const cursorPointer = css`
  cursor: pointer;
`;

export const Padding0y50x = css`
  padding: 0px 50px;
`;

export const fontThin = css`
  font-weight: 200;
`;

export const fontRegular = css`
  font-weight: 400;
`;

export const fontMedium = css`
  font-weight: 500;
`;

export const fontBold = css`
  font-weight: 700;
`;

export const FontLight = styled.b`
  font-weight: 400;
  color: ${(props) => props.theme.primary.fontLight};
`;

export const FlexCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
`;

export const DisplayCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
  flex: 1;
`;

export const rowSpaceBetween = styled.div`
  ${displayFlex};
  flex-flow: column;
`;

export const AppContainer = styled.div`
  width: 100%;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  height: 100%;

  .piechart-wrapper {
    border-bottom: solid;
  }
`;

export const MainContainer = styled.div`
  overflow: auto;
`;

export const AdView = styled.div`
  width: ${sideBar};
  background-color: #ccc;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  background-image: linear-gradient(to right, #7073c7, #3f51b5);
`;

export const ArrowView = styled.div<Selected>`
  position: fixed;
  right: ${(props) => (props.isOpen ? "200px" : "0")};
  bottom: 0;
  background-color: ${(props) => props.theme.primary.whiteColor};
  border: solid 2px ${(props) => props.theme.primary.color};
  border-radius: 100px;
  width: ${arrowDimentions};
  height: ${arrowDimentions};
  ${displayFlex};
  ${flexCenter};
  ${cursorPointer};
  z-index: 9999;
`;

export const HomeImageView = styled.div`
  ${displayFlex};
  ${flexCenter};

  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
`;

export const TitleHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    width: 100% !important;
  }

  h5 {
    ${fontMedium};
    text-transform: capitalize;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.primary.secondaryColor};
  }

  .title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 0;
    color: ${(props) => props.theme.primary.primaryColor};
    font-style: italic;

    span {
      ${fontBold};
      font-style: normal;
      ${fontBold};
      font-family: "Playfair Display", serif;
      letter-spacing: 1.2px;
      color: ${(props) => props.theme.primary.secondaryColor};
    }
  }
`;

export const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;

  @media (max-width: 767px) {
    position: relative;
  }
`;

export const SpecialFont = styled.b`
  color: ${(props): string => props.theme.primary.secondaryColor};
  font-size: 25px;
`;

export const CareersWrapper = styled.div`
  font-size: 16px;

  .rs-process {
    background-color: #f9f9f9;
    padding: 30px 0;
  }

  .sec-title {
    margin-bottom: 30px;
    text-align: center;
  }

  section {
    margin-top: 20px;
    margin-bottom: 40px;

    h4 {
      margin-bottom: 30px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 700;
    color: #333;
  }

  .number-part {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-in-out;
    margin-bottom: 30px;
    padding: 50px;
    padding-top: 20px;

    i {
      color: #51519a;
    }
  }

  .number-part:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  }

  .number-title {
    margin-bottom: 0;
  }

  .number-txt {
    color: #666;
  }

  .loac-text {
    margin-bottom: 10px;
  }

  .btn-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .readon {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: white;
    background: #51519a;
    text-decoration: none;
  }

  .readon:hover {
    background-color: #5151r;
    color: #fff;
  }

  /* Style for unordered list */
  ul {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
    list-style: inherit;
    padding-left: 30px;
  }

  /* Style for list items */
  li {
    margin-bottom: 10px;
    color: #444;
    font-size: 16px;
    line-height: 1.6;
  }

  strong,
  h4 {
    font-weight: bold;
    color: black;
  }

  .success-wrapper {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;

    h2 {
      color: #4caf50;
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #333;
    }
  }
`;

export const SuccessWrapper = styled.div`
  background-color: rgb(251 251 251);
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  h2 {
    color: #4caf50;
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  i {
    color: green;
  }

  p {
    font-size: 26px;
    margin-top: 20px;
  }
`;

export const FormWrapper = styled.section`
  border: solid 1px #eee;
  padding: 30px 50px;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px #d2cece;
  border-radius: 12px;
  height: 100%;
  padding-bottom: 50px;
  width: 80%;
  color: black;

  @media (max-width: 480px) {
    width: 100%;
  }

  h2 {
    padding-bottom: 30px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    input,
    select {
      border-radius: 6px;
      width: 100%;
      min-height: 40px;
      border: solid 1px #eee;
      padding-left: 8px;
      margin-top: 4px;
      color: black;
    }
  }

  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 20px;
  }

  #submit-btn {
    background-color: #51519a;
    border: none;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
