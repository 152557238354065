import React from "react";
import Img14 from "../../../assets/images/14.png";

export default function ConstructionManagement() {
  const Img31 = require("../../../assets/images/31.gif");

  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">Construction management</h2>
                <p className="margin-0 pb-20">
                  Because visibility is the key to success, VSIT Systems INC's
                  genuine Cloud ERP Construction Management software is made to
                  provide construction enterprises with solid business
                  information.. Improved compliance control, immediate data
                  access through role-based dashboards, native AI and ML for
                  automation, and adaptable drop-shipping to project sites.
                </p>
                <p className="margin-0 pb-20">
                  A complete cloud-based and mobile construction and accounting
                  software solution is offered by VSIT Systems INC. The
                  application was created for today's complex, interconnected
                  construction sector. This user-friendly platform offers
                  several features, including strong financials, work cost
                  accounting, project management, payroll, inventory, order
                  management, service management, equipment management, route
                  optimization, CRM, and mobile capabilities. It provides a
                  full, real-time perspective of your organization from any
                  location, at any time, by employing the most recent cloud and
                  mobile technology.
                </p>

                <div className="images-part mt-0 mb-20">
                  <img src={Img31} alt="Images" />
                </div>

                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      A tool for managing projects, costs, and enterprise
                      capacity that links opportunities, contracts, schedules,
                      budgets, change orders, subcontracts, and compliance from
                      a single screen.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Be aware of scope adjustments and their cost implications.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      From a single location, it is possible to manage the
                      project's budget and keep track of all activities, issues,
                      changes orders, project documentation, and job rates.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Easily manage labor, budgets, and scheduling.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Give a quick overview of the current project to everyone
                      in the field and the home office.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img src={Img14} alt="Images" />
              </div>
            </div>
            <div className="col-lg-6 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">Central management of finances</h2>
                <p className="margin-0 pb-20">
                  Use construction accounting software to precisely estimate and
                  manage spending, wages, profits, cash, and taxes using revenue
                  balance, % finished, and completed contract accounting, along
                  with support for different businesses, currencies, and
                  languages. Handle materials and subcontractors utilizing
                  purchase orders, inventory control, and advanced warehousing
                  management.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Accounting for job costs</a>
                  </h2>
                  <p className="services-txt">
                    Find out how much it will cost to complete your project, how
                    much it will cost when it is finished, and what % of it is
                    finished. This detailed job information may help you
                    estimate future projects more accurately.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                {/* <div className="services-icon">
                                  <img src="assets/images/services/style3/2.png" alt="Services">
                              </div> */}
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">Prioritize the project over the paperwork. </a>
                  </h2>
                  <p className="services-txt">
                    VSIT Systems INC streamlines and simplifies document
                    management. Documents can be shared with internal and
                    external users to speed up collaboration because there are
                    no per-user fees or access limitations based on user roles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}

      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-20">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-0">
                  On any device, at any time, and anywhere
                </h2>
                <p className="mt-10">
                  Having a complete picture of your project, including real-time
                  field updates, enables constant communication between your
                  project team, back office, and management.
                </p>
              </div>
            </div>
          </div>
          <div className="row y-middle mt-20">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  Reliable methods and agile teams
                </h2>
                <p className="mt-10">
                  Modern distributed teams need to be able to operate from any
                  place, adapt rapidly, and maintain smooth operations. This is
                  why VSIT Systems INC was created.
                </p>
                <p className="mt-10">
                  We looked for a service that was cloud-optimized before
                  settling on VSIT Systems INC TRUE Cloud ERP. All Security
                  Solutions divisions, including locksmith, retail, service,
                  sales, inspections, and testing, have fast access to
                  information because of CRM and the Mobile App. This
                  facilitates business and allows us to focus on what we do
                  best: develop security solutions that provide comfort.
                </p>
                <p className="mt-10">
                  Contracts, accounting, and the project plan are all closely
                  related to budgets for real-time control of hard and soft
                  expenses, change orders, profits, overheads, and fees.
                </p>
                <p className="mt-10">
                  Modern distributed teams need to be able to operate from any
                  place, adapt rapidly, and maintain smooth operations. This is
                  why VSIT Systems INC was created.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
