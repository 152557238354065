import React from "react";
import Img2 from "../../../assets/images/person-using-ai-tool-job.jpg";
import { Link } from "react-router-dom";

export default function ITStrategyConsulting() {
  return (
    <div>
      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle mt-20">
            <div className="col-lg-12 ">
              <div className="sec-title3">
                <h2 className="title pb-20">
                  With the Help of Our Skilled IT Strategy Consulting Services,
                  <br />
                  Drive Digital Transformation
                </h2>
                <p className="mt-10">
                  We collaborate closely with the executive team of your company
                  to create a strategic IT roadmap that includes projects,
                  milestones, and goals that are all in line with your business
                  objectives.
                </p>
                <p className="mt-10">
                  Our strategy includes both short- and long-term IT activities,
                  such as process optimisation, digital transformation, and
                  technology improvements.
                </p>

                <div className="images-part pt-30 pb-50">
                  <img src={Img2} alt="Images" className="w-75 " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services home-style2 pt-50 pb-50 md-pt-50 md-pb-50 gray-bg">
        <div className="container">
          <div className="sec-title2 text-center md-left mb-40">
            <h2 className="title pb-20">Our IT Strategy Consulting Services</h2>
            <p>
              We bring a wealth of expertise to the table, boasting a team
              proficient in cutting-edge <br />
              technologies and a track record of successful projects.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 md-mb-50 pr-30 md-pr-l5">
              <div className="services-item mb-45">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="data-strategy">Organization Management</Link>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    Our change management specialists assist in reducing
                    resistance to change and guarantee the organization's
                    seamless adoption of new IT procedures and technologies.
                  </p>
                </div>
              </div>

              <div className="services-item mb-45">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="data-science"> IT Roadmap and Development</Link>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    Our roadmap encompasses short-term and long-term IT
                    initiatives, including technology upgrades, digital
                    transformation initiatives, and process optimization
                    efforts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-15">
              <div className="services-item mb-45">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="snowflake-administration">
                      Technology Alignment
                    </Link>
                  </h3>
                  <p className="services-txt">
                    Our specialists offer suggestions for modernization,
                    integration, and adoption of new technologies to make sure
                    your IT infrastructure efficiently serves your company
                    needs.
                  </p>
                </div>
              </div>

              <div className="services-item mb-45">
                <div className="services-text">
                  <h3 className="title">
                    <Link to="data-engineering">
                      IT Assessment and Gap Analysis{" "}
                    </Link>
                  </h3>
                  <p className="services-txt">
                    Our gap analysis assists in identifying potential areas in
                    which your IT strategy is not aligned with business goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row y-middle mt-20">
        <div className="d-flex justify-content-around mt-30 box-content">
          <div className="sec-title3 d-flex justify-content-center flex-column">
            <h2 className="title pb-10 text-center">
              {" "}
              So, why do you hesitate?{" "}
            </h2>
            <h2 className="title pb-20">
              Start using VSIT Systems INC right now to experience the impact it
              can have on your company!
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
