import React, { useRef } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Contactus = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs
    //   .sendForm(
    //     "service_wbs7pus",
    //     "template_nehnvk3",
    //     form.current,
    //     "2D-ybvemeQmDGowSt"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       Swal.fire({
    //         title: "Thank you for registering!",
    //         text: "We will get back soon.",
    //         icon: "success",
    //         confirmButtonText: "OK",
    //       });
    //     },
    //     (error) => {
    //       console.log(error.text);
    //       Swal.fire({
    //         title: "Error!",
    //         text: error?.response?.data?.error?.message
    //           ? error?.response?.data?.error?.message
    //           : "Something went wrong!",
    //         icon: "error",
    //         confirmButtonText: "OK",
    //       });
    //     }
    //   );
    // e.target.reset();
  };

  return (
    <>
      <div>
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img8">
          <div className="container">
            <div className="breadcrumbs-inner">
              <h1 className="page-title">
                Have questions - contact us
                <span className="watermark">Contact</span>
              </h1>
              <span className="sub-text">We help you.</span>
            </div>
          </div>
        </div>
        {/* Breadcrumbs End */}

        {/* Contact Section Start */}
        <div className="rs-contact contact-style2 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
          <div className="container">
            <div className="row margin-0">
              <div className="col-lg-6 padding-0">
                <div className="contact-address">
                  <div className="sec-title mb-46">
                    <h2 className="title pb-20">Get in touch</h2>
                    <p className="margin-0">
                      Objectively innovate your empowered manufactured products
                      whereas parallel platforms for your ideas.
                    </p>
                  </div>
                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/1.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      6200 chase oak Dr Suite 101 Plano Texas 75023
                    </div>
                  </div>
                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/5.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      <a href="tel:(+088)589-8745">+1(959)202-3685</a>
                      <br />
                    </div>
                  </div>
                  <div className="address-item mb-25">
                    <div className="address-icon">
                      <img
                        src="assets/images/contact/icons/6.png"
                        alt="Address"
                      />
                    </div>
                    <div className="address-text">
                      <a href="mailto:info@vsitsystems.com">
                        info@vsitsystems.com
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 padding-0">
                <div className="contact-map" style={{ height: "512px" }}>
                  <iframe
                    title="video"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3343.903881209176!2d-96.70122122353435!3d33.05899727354722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c19c9acc1b631%3A0x4df697c6478963cc!2s6200%20Chase%20Oaks%20Blvd%20%23101%2C%20Plano%2C%20TX%2075023%2C%20USA!5e0!3m2!1sen!2sin!4v1708684117235!5m2!1sen!2sin"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Section End */}

        {/* Contact Section Start*/}
        <div className="rs-contact main-home office-modify1 pt-30 pb-30 md-pt-30 md-pb-30">
          <div className="container">
            <div className="row margin-0">
              <div className="col-lg-6 padding-0 office-buliding" />
              <div className="col-lg-6 padding-0">
                <div className="contact-section contact-style2">
                  <div className="contact-wrap">
                    <div id="form-messages" />
                    <form id="checkout-form" ref={form} onSubmit={sendEmail}>
                      <div className="row">
                        <div className=" form">
                          <h2 className="sidebar-title mb-30">
                            Please fill the form and Submit
                          </h2>
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                              name="name"
                            />
                          </div>

                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter your email address"
                              name="email"
                            />
                          </div>
                          <div className="form-group">
                            <label>Mobile Number</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter your mobile number"
                              name="mobile"
                            />
                          </div>

                          <div className="form-group">
                            <label>Write a Query</label>
                            <textarea
                              type="text"
                              className="form-control"
                              placeholder="Type your query"
                              name="suggestions"
                            />
                          </div>

                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <div className="pLace-order mt-30">
                                <button
                                  className="view-all-accent-btn"
                                  type="submit"
                                  value="Submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Section End */}
      </div>
    </>
  );
};

export default Contactus;
