import React from "react";
import { Link } from "react-router-dom";

//components
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Banner1 from "../../assets/images/banner1.svg";
import Banner2 from "../../assets/images/banner2.svg";
import Img42 from "../../assets/images/42.svg";

import P1 from "../../assets/images/p1.svg";
import Slider from "react-animated-slider";

function Home() {
  const content = [
    {
      title: "Empowering Businesses Through Technology",
      description:
        "We believe in the power of technology to transform businesses and drive innovation. ",
      button: "Read More",
      image: Banner1,
    },
    {
      title: " Empowering Growth Through Technology ",
      description:
        "Our dedicated team of experts is committed to helping you harness the power of technology to drive growth, streamline operations, and stay ahead of the competition.",
      button: "Discover",
      image: Banner2,
    },
  ];

  return (
    <>
      <div>
        <Slider className="slider-wrapper">
          {content.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${item.image}') no-repeat center center`,
              }}
            >
              <div className="inner">
                <h1>{item.title}</h1>
                <p>{item.description}</p>
                {/* <button>{item.button}</button> */}
              </div>
            </div>
          ))}
        </Slider>
        <div class="rs-services style7 gray-bg3 pt-30 pb-30">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 md-mb-30">
                <div class="services-item">
                  <div class="services-icon">
                    <img
                      src="assets/images/services/style7/icons/1.png"
                      alt="Images"
                    />
                  </div>
                  <div class="services-content">
                    <h2 class="title"> Custom Software Development</h2>
                    <p class="services-txt">
                      {" "}
                      Tailored solutions crafted to match your unique business
                      requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 md-mb-30">
                <div class="services-item">
                  <div class="services-icon">
                    <img
                      src="assets/images/services/style7/icons/2.png"
                      alt="Images"
                    />
                  </div>
                  <div class="services-content">
                    <h2 class="title">IT Consulting Services</h2>
                    <p class="services-txt">
                      {" "}
                      Expert guidance to leverage technology effectively and
                      achieve business goals.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="services-item">
                  <div class="services-icon">
                    <img
                      src="assets/images/services/style7/icons/3.png"
                      alt="Images"
                    />
                  </div>
                  <div class="services-content">
                    <h2 class="title"> Managed IT Services</h2>
                    <p class="services-txt">
                      {" "}
                      24/7 clients servicesProactive support ensuring
                      reliability, security, and performance of IT
                      infrastructure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="rs-faq faq-home-style5 pt-70 pb-30 md-pt-70 md-pb-30">
          <div class="container">
            <div class="sec-title3 text-center mb-60">
              {/* <div class="icon-part mb-25">
                                    <img src="assets/images/brand-logo.svg" alt="" style={{width: '200px'}}/>
                                </div> */}
              <h2 class="title pb-20">
                Choose <b>VSIT Systems INC</b> as your committed partner for
                success.
              </h2>
              <p class="dese margin-0">
                Utilize VSIT Systems INC to collect, manage, and organize
                business information
                <br /> with cutting-edge functionality, technology, and
                intuitive features.
              </p>
            </div>
            <div class="row y-middle">
              <div class="col-lg-6 pl-55 md-pl-15">
                <div class="content-part">
                  <div class="faq-content">
                    <div id="accordion" class="accordion">
                      <div class="card">
                        <div class="card-header">
                          <a
                            class="card-link collapsed"
                            href="/"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                          >
                            What services does your software company and
                            consultancy firm provide?
                          </a>
                        </div>
                        <div
                          id="collapseFour"
                          class="collapse show"
                          data-bs-parent="#accordion"
                        >
                          <div class="card-body mt-30">
                            <ul class="icon-item">
                              <p>
                                We specialize in various services, including
                                software development, consultancy, and
                                customized solutions. Our expertise covers a
                                wide range of domains, from advanced
                                technologies such as Big Data and Cloud
                                Computing to bespoke software development and
                                strategic consulting.
                              </p>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <a
                            class="card-link collapsed"
                            href="/"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                          >
                            What is your company's approach to the software
                            development process?
                          </a>
                        </div>
                        <div
                          id="collapseFive"
                          class="collapse"
                          data-bs-parent="#accordion"
                        >
                          <div class="card-body mt-30">
                            <ul class="icon-item">
                              <p>
                                Our software development process emphasizes
                                collaboration and iteration. We engage closely
                                with clients to grasp their needs, integrate
                                feedback iteratively, and ensure the final
                                product reflects their vision.
                              </p>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <a
                            class="card-link collapsed"
                            href="/"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseSix"
                            aria-expanded="false"
                          >
                            Which industries does your consultancy serve?
                          </a>
                        </div>
                        <div
                          id="collapseSix"
                          class="collapse"
                          data-bs-parent="#accordion"
                        >
                          <div class="card-body mt-30">
                            <ul class="icon-item">
                              <p>
                                We possess extensive experience across various
                                industries, encompassing finance, healthcare,
                                e-commerce, and telecommunications, among
                                others. Our team's versatility enables us to
                                customize solutions to address the distinct
                                challenges and needs of each industry,
                                delivering tailored software and consultancy
                                services that yield tangible results.
                              </p>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <a
                            class="card-link collapsed"
                            href="/"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                          >
                            How might our organization gain from engaging your
                            consultancy services?
                          </a>
                        </div>
                        <div
                          id="collapseOne"
                          class="collapse"
                          data-bs-parent="#accordion"
                        >
                          <div class="card-body mt-30">
                            <ul class="icon-item">
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Our consultancy services aim to empower your
                                  organization with strategic insights,
                                  technological expertise, and tailored
                                  solutions.
                                </span>
                              </li>
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Whether you aim to enhance existing processes,
                                  adopt new technologies, or undergo digital
                                  transformation, our consultancy team
                                  collaborates closely with you to efficiently
                                  achieve your business objectives.
                                </span>
                              </li>

                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Provides comprehensive insights via intuitive
                                  dashboards.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <a
                            class="card-link collapsed"
                            href="/"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                          >
                            How can we keep abreast of your company's updates
                            and industry insights?
                          </a>
                        </div>
                        <div
                          id="collapseTwo"
                          class="collapse"
                          data-bs-parent="#accordion"
                        >
                          <div class="card-body mt-30">
                            <ul class="icon-item">
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Stay connected with us through our newsletter,
                                  social media platforms, and blog for the
                                  latest updates and industry insights.
                                </span>
                              </li>
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  We consistently share updates on our recent
                                  projects, industry insights, and thought
                                  leadership articles.
                                </span>
                              </li>
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Become a part of our community to access
                                  valuable information, engage in discussions,
                                  and stay updated on the dynamic intersection
                                  of technology and business.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <a
                            class="card-link collapsed"
                            href="/"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                          >
                            Highly regarded by our clients.
                          </a>
                        </div>
                        <div
                          id="collapseThree"
                          class="collapse"
                          data-bs-parent="#accordion"
                        >
                          <div class="card-body mt-30">
                            <ul class="icon-item">
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Perfect fit for small and medium-sized
                                  businesses.
                                </span>
                              </li>
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Affordable solutions tailored for small and
                                  medium-sized enterprises.
                                </span>
                              </li>
                              <li>
                                <span class="list-icon">
                                  <i class="fa fa-check"></i>
                                </span>
                                <span class="list-text">
                                  Satisfied customers worldwide.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 padding-0 md-mb-50">
                <div class="rs-videos faq">
                  <img src={P1} alt="" />
                  <div class="animate-border">
                    <a
                      class="popup-border"
                      href="https://www.youtube.com/watch?v=JK2MdJAWEGc&list=PLlgLmuG_KgbasW0lpInSAIxYd2vqAEPit&ab_channel=GreatLearning"
                    >
                      <i class="fa fa-play"></i>
                      {/* <video width="320" height="240" controls>
                                                    <source src={Img7} type="video/mp4"/>
                                                </video> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rs-services home-style2 pt-50 pb-50 md-pt-50 md-pb-50 gray-bg">
          <div className="container">
            <div className="sec-title2 text-center md-left mb-40">
              <h2 className="title pb-20">
                Our services facilitate the growth of your company.
              </h2>
              <p>
                We bring a wealth of expertise to the table, boasting a team
                proficient in cutting-edge <br />
                technologies and a track record of successful projects.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 md-mb-50 pr-30 md-pr-l5">
                <div className="services-item mb-45">
                  <div className="services-text">
                    <h3 className="title">
                      <Link to="data-engineering">Data engineering</Link>
                    </h3>
                    <p className="services-txt">
                      Data Engineers at VSIT Systems INC excel in managing and
                      transforming raw data into high-value assets. They play a
                      pivotal role in assisting you in constructing robust and
                      efficient cloud platforms.
                    </p>
                  </div>
                </div>

                <div className="services-item">
                  <div className="services-text">
                    <h3 className="title">
                      <Link to="snowflake-administration">
                        Enterprise flexibility
                      </Link>
                    </h3>
                    <p className="services-txt">
                      Ensure readiness for any potential disaster by investing
                      in high availability, regular backups, and multiple data
                      storage locations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-40 md-pl-15">
                <div className="services-item mb-45">
                  <div className="services-text">
                    <h3 className="title">
                      <Link to="data-science">Confident about the future</Link>
                    </h3>
                    <p className="services-txt">
                      {" "}
                      Ensure your company's preparedness for the future by
                      implementing a mobile-first, telework architecture that
                      ensures operational continuity and business resilience.
                    </p>
                  </div>
                </div>
                <div className="services-item mb-45">
                  <div className="services-text">
                    <h3 className="title">
                      <Link to="data-strategy">Continual time</Link>
                    </h3>
                    <p className="services-txt">
                      {" "}
                      You can access and analyze your business data from any
                      device, anywhere, and at any time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="rs-about main-home pt-70 pb-70 md-pt-70 md-pb-70">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 md-mb-50">
                <div class="images-part">
                  <img src={Img3} alt="Images" className="w-100" />
                </div>
              </div>
              <div class="col-lg-6 pl-46 md-pl-15">
                <div class="sec-title3">
                  <h2 class="title pb-10">
                    <i>Unleashing Efficiency</i> <br />
                    Explore VSIT Systems INC Cloud ERP in Action
                  </h2>
                  <p class="margin-0 pb-10 pt-15">
                    A demonstration of the Cloud ERP Schedule Personalised VSIT
                    Systems INC Cloud ERP Overview product, featuring real-time
                    observations accessible at any time and from any location.
                    You may effectively manage your company, evaluate customer
                    interactions, and get proactive business insights at any
                    time, from any place, on any device, with the aid of VSIT
                    Systems INC' cloud ERP application suite.
                  </p>

                  <ul class="icon-item pt-15">
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        ERP on the cloud from VSIT Systems INC
                      </span>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        I observed it working for ten minutes.
                      </span>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">One-Hour Tour</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* About Section Start */}
        <div className="rs-about gray-bg2 style1 pt-30 pb-30 md-pt-30 md-pb-30">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12 pr-70 md-pr-15 md-mb-50">
                <div className="sec-title2 mb-30">
                  <div className="sub-text">Who We Are</div>
                  <h2 className="title mb-23">
                    At VSIT Systems INC, we distinguish ourselves as your
                    premier choice for several compelling reasons:
                  </h2>
                  <p className="desc mb-0">
                    At VSIT Systems INC, our forte resides in our profound
                    expertise. Our team is adept in the latest cutting-edge
                    technologies, bolstered by a track record of successfully
                    executed projects. Choosing us means accessing a wealth of
                    knowledge and skill, guaranteeing the success of your
                    project.
                  </p>
                </div>
                {/* Skillbar Section Start */}
                <div className="rs-skillbar style1">
                  <ul class="icon-item">
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        We bring a wealth of expertise to the table, boasting a
                        team skilled in cutting-edge technologies and a proven
                        track record of successful projects.
                      </span>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        Our dedication to comprehending your distinct needs
                        enables us to customize solutions that precisely align
                        with your business requirements.
                      </span>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        We believe in collaboration. By working closely with
                        you, we ensure transparency, feedback, and a seamless
                        process from concept to delivery.
                      </span>
                    </li>
                  </ul>
                </div>
                {/* Skillbar Section End */}
              </div>
              {/* <div className="col-lg-4">
                                    <div className="about-img">
                                        <img src={Img38} alt="images" style={{width: '80%'}}/>                                      
                                    </div>
                                </div> */}
            </div>
          </div>
        </div>
        {/* About Section Start */}

        <div class="rs-about main-home pt-70 pb-70 md-pt-70 md-pb-70">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 pl-46 md-pl-15">
                <div class="sec-title3">
                  <h1 class="pb-10 hidden-xs">
                    Customized Solutions for Every Need
                  </h1>
                  <p className="mt-40">
                    At VSIT Systems INC, we don't just develop software; we
                    understand that one size does not fit all. That's why we
                    take a personalized approach to every project, working
                    closely with you to understand your specific goals and
                    challenges. Whether you're looking for custom software
                    development, IT consulting, or managed services,
                  </p>
                  <p class="margin-0 pb-10  mt-15">
                    we have the expertise and experience to deliver solutions
                    that exceed your expectations.
                  </p>

                  {/* <p class="margin-0 pb-10  mt-15">
                    When you partner with us, you tap into a reservoir of
                    experience that transcends sectors, ensuring your success in
                    any domain.
                  </p> */}
                  <ul class="icon-item mt-15">
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        Collaboration for Brilliance{" "}
                      </span>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        Agile Excellence, On Time, Every Time
                      </span>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="fa fa-check"></i>
                      </span>
                      <span class="list-text">
                        Versatility Across Industries
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-5 md-mb-50">
                <div class="images-part">
                  <Link to="/contact-us">
                    <img src={Img42} alt="Images" className="w-100" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
