import React from "react";

export default function DataScience() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      {/* <div className="rs-breadcrumbs img14 aws-banner">
                <div className="container">
                    <div className="breadcrumbs-inner">
                        <h1 className="page-title">
                            What is <b className='' style={{fontSize: '6rem', color: '#ffe941'}}>Data Science?</b>
                            <span className="watermark"></span>
                        </h1>
                        <span className="sub-text">Data science is one of the most in-demand occupations of the decade, with a need for data scientists who can analyse data and convey findings to guide data-driven choices at an all-time high. 
                        </span>                        
                    </div>
                </div>
            </div> */}
      {/* Breadcrumbs End */}

      <div
        id="rs-about"
        className="rs-about main-home pt-30 pb-30 md-pt-30 md-pb-30"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12 pl-46 md-pl-15">
              <div className="sec-title3">
                <h2 className="title pb-30">
                  Business Insights with Data Science
                </h2>
                <p className="margin-0 pb-40">
                  we harness the power of data science to drive informed
                  decision-making and unlock valuable insights for our clients.{" "}
                </p>
                <ul className="icon-item">
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Our data science services enable businesses to make
                      decisions based on empirical evidence rather than
                      intuition alone.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      {" "}
                      With advanced predictive analytics techniques, we forecast
                      future trends and outcomes, empowering our clients to
                      anticipate market shifts, customer preferences, and
                      business risks.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      Data science plays a crucial role in optimizing business
                      operations. From supply chain management to resource
                      allocation, we utilize data-driven models to streamline
                      processes, enhance efficiency, and minimize costs.
                    </span>
                  </li>
                  <li>
                    <span className="list-icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="list-text">
                      In today's competitive landscape, personalized experiences
                      are key to customer engagement and loyalty.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 md-mb-50 mt-30">
              <div className="images-part">
                <img
                  src="assets/images/pages/data-science/2.png"
                  alt="Images"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section End */}
      <div className="rs-services style3 gray-bg pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">Adoption of DATA SCIENCE in Finance!</h2>
            <p className="mt-20">
              Professional certificates in Data Science can help prepare you for
              the workforce, whether you're embarking on a new career path or{" "}
              transitioning within your current one. Enjoy the flexibility of
              self-paced learning, accessible anytime and anywhere.
            </p>
            <img
              src="assets/images/pages/data-science/3.png"
              alt="Services"
              className="mt-30 w-100"
            />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Enroll today to embark on your journey toward a new
                      professional trajectory. Pause or cancel your membership
                      at any time to suit your needs.
                    </a>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 mb-50">
              <div className="services-item">
                <div className="services-content">
                  <h2 className="services-title">
                    <a href="/">
                      Significant emphasis is placed on applied learning in this
                      Professional Certificate. Following the initial course,
                      all subsequent modules include hands-on labs on the Cloud,
                      offering practical skills applicable to real-world
                      scenarios.
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services Section End */}
      {/* <div className="rs-services style3 pt-30 pb-30 md-pt-30 md-pb-30">
        <div className="container">
          <div className="sec-title mb-60">
            <h2 className="title">How will Data Science change the world?</h2>

            <div className="mt-30 mb-30">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/_fjUaDj77NI"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div> */}

      {/* Partner Section Start */}
      {/* <div className="rs-patter-section black-bg pt-80 pb-75">
        <div className="container custom">
          <div
            className="rs-carousel owl-carousel"
            data-loop="true"
            data-items={5}
            data-margin={30}
            data-autoplay="true"
            data-hoverpause="true"
            data-autoplay-timeout={5000}
            data-smart-speed={800}
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-md-device={5}
            data-md-device-nav="false"
            data-md-device-dots="false"
            data-center-mode="false"
            data-ipad-device2={4}
            data-ipad-device-nav2="false"
            data-ipad-device-dots2="false"
            data-ipad-device={4}
            data-ipad-device-nav="false"
            data-ipad-device-dots="false"
            data-mobile-device={2}
            data-mobile-device-nav="false"
            data-mobile-device-dots="false"
          >
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/1.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/2.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/3.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/4.png"
                  alt=""
                />
              </a>
            </div>
            <div className="logo-img">
              <a href="/">
                <img
                  className="hovers-logos rs-grid-img"
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
                <img
                  className="mains-logos rs-grid-img "
                  src="assets/images/partner/main-home/5.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* Partner Section End */}
    </div>
  );
}
